import Tree from "../widgets/Tree";
import { treeJson } from "../db/TreeJson";

const TreePage = (p) => {

    return (
        <div className="page">
            <div className="flex-24">
                <div className="col-16 flex-column">
                    <div className="page-box">
                        <h4>Product Schema</h4>
                        <div className="page-box-tree">
                            <Tree json={treeJson} />
                        </div>
                    </div>
                </div>
                <div className="col-8 flex-column">

                </div>
            </div>
        </div>
    );
};

export default TreePage;