import { BrowserRouter as Router, Route, Switch, useLocation, Link } from 'react-router-dom';

const Pagination = () => {

    const location = useLocation();
    const segmants = location.pathname.split('/').filter(Boolean);
    const current = segmants[segmants.length - 1] || 1;

    return (
        <div className="pagination">
            <ul>
                {segmants && segmants.map((segment, index) => (
                    <li key={segment + index}>
                        <Link to={`/${segmants.slice(0, index + 1).join('/')}`} className={current === segment ? 'pagination-current-a' : ''}>
                            {segment.replace('-', ' ')}{(index < segmants.length - 1) && (<span>{'>'}</span>)}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Pagination;