import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import BasicDataTable from "../../widgets/BasicDataTable";
import { ServicesActions } from "../../redux/slices/ServicesSlice";
import { $serializeForm, makeUrl, trimStringGaps } from "../../main";
import { GET_ALL_SERVICE, UPDATE_SERVICE } from "../../api/Api";
import { Loading } from "../Loaders";
import { LoadingSvg } from "../../icons/Icon";
import ServiceForm from "./ServiceForm";

const SearchService = () => {

    const dataTable = useRef(null);
    const dispatch = useDispatch();
    const services = useSelector(state => state.services.data);
    const [serviceData, setServiceData] = useState(null);

    useEffect(() => {
        dispatch(ServicesActions.getAllService(GET_ALL_SERVICE));
    }, []);
    function changed(e) {
        e.preventDefault();
    }
    function submit(service) {
        var [encoded, json] = $serializeForm(service);
        
        if(Array.isArray(json['metaName']) && Array.isArray(json['metaContent'])){
            json['meta'] = {};
            json['metaName'].forEach((metaName, index) => {
                json['meta'][metaName] = json['metaContent'][index];
            });
        }
        console.log('search.service.json', json);
        //dispatch(ServicesActions.putService({ ...UPDATE_SERVICE(json.id), data: json }));
    }
    const closeForm = () => {
        dataTable.current.deselect();
    }
    const doEdit = (e) => {
        let edit = {...e.original};
        if('meta' in edit){
            edit.meta = JSON.parse(e.original.meta);
        }
        //console.log('doEdit', edit);
        setServiceData(edit);
    };
    const doDelete = (e) => {
        console.log('delete', e);
    };
    const doView = (e) => {
        console.log('view', e);
    };
    const getSelected = (e) => {
        let selected = dataTable?.current.getSelectedRows();
        console.log(selected)
    }

    return (
        <div className="page">
            <div className="flex-24">
                <div className="col-24 flex-column">
                    <div className="page-box">
                        <h4>Services</h4>
                        {
                            services ?
                                <BasicDataTable data={services} ref={dataTable} settings={{ show: ['name', 'url', 'abstract'], selectable: false, actions: { edit: doEdit, delete: doDelete, view: doView, deselect: getSelected } }} /> :
                                <Loading><LoadingSvg /></Loading>
                        }
                    </div>
                </div>
            </div>
            <ServiceForm onSubmit={submit} onChange={changed} data={serviceData} />
        </div>
    );
};

export default SearchService;