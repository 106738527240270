import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { $serializeForm } from "../../main";
import Meta from "../../widgets/Meta";

const NewProduct = () => {

    const unit = useRef();
    function formChanged(e) {
        e.preventDefault();

    }
    function submit(e){
        e.preventDefault();
        var [encoded, json] = $serializeForm(unit.current);
        console.log(json);
    }
    return (
        <div className="page">
            <form action="/submit" ref={unit} onChange={formChanged} className="simple-form" method="POST" encType="multipart/form-data" autoComplete="on" target="_blank" noValidate>
                <div className="flex-24">
                    <div className="col-19 flex-column">
                        <div className="page-box">
                            <h4>title</h4>
                            <div className="flex-column">
                                <label title="type unit name"><span>Name</span><input type="text" name="name" placeholder="" defaultValue='' /></label>
                                <label title="type unit name"><span>Details</span><textarea name="headerScript" rows="6" placeholder="" defaultValue='' ></textarea></label>
                            </div>
                        </div>
                        <div className="page-box">
                            <h4>Header Script</h4>
                            <div className="flex-row">
                                <label title="type unit name"><textarea name="headerScript" rows="6" placeholder="" defaultValue='' ></textarea></label>
                            </div>
                        </div>
                        <div className="page-box">
                            <h4>Footer Script</h4>
                            <div className="flex-row">
                                <label title="type unit name"><textarea name="footerScript" rows="6" placeholder="" defaultValue='' ></textarea></label>
                            </div>
                        </div>
                        <Meta />
                    </div>
                    <div className="col-5 flex-column">
                        <div className="page-box">
                            <h4>Create Product</h4>
                            <div className="flex-row">
                                <button onClick={submit}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div style={{ height: '100px' }}></div>
        </div>
    );
};

export default NewProduct;