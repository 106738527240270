import { useState, useRef, useEffect } from 'react';
import { Checkbox } from "./FormComponents";
import { AddToList, SubDirectoryRight, TickCircle, EditBox, DeleteSvg, UploadSvg } from "../icons/Icon";

let root = null;
const addRoot = (json) => {
    if (!('id' in json)) {
        json['id'] = -1;
        json['value'] = '\\root';
        json["parent"] = null;
        let array = [];
        array.push(json);
        root = json;
        //console.log('addroot', array);
        return array;
    }
    return null;
};
const isRoot = (id) => {
    return id === 'root';
};
/*const findNodeById = (id, nodes) => {
    //console.log('findNodeById', id, nodes)
    for (const node of nodes) {
        //console.log('if', node.id, id)
        if (node.id === id) {
            return node;
        }
        if (node.nodes && node.nodes.length > 0) {
            const found = findNodeById(id, node.nodes);
            if (found) {
                return [found, node];
            }
        }
    }
    return null;
};*/
const insertInto = (node, parentId, json, order = null) => {
    //node = node || findNodeByIdStack(node.id, json)?.node;
    //console.log('insertInto', parentId, findNodeByIdStack(parentId, json))
    var parent = findNodeByIdStack(parentId, json)?.node || root;
    //console.log('insertInto.parent', parentId, parent)
    var done = false;
    if (!parent) {
        console.error("Parent node or nodes array not found!");
        return;
    }
    if (!(parent.nodes && Array.isArray(parent.nodes))) {
        parent['nodes'] = [];
    }
    if (order === null) {
        done = parent.nodes.push(node);
        node.order = done - 1;
        node.parent = parent.id || -1;
       
    } else {
        node.parent = parent.id || -1;
        var inserted = false;
        for (let i = 0; i < parent.nodes.length; i++) {
            console.log('inserting',i,order,i == order )
            if (parent.nodes[i].order == order) {
                parent.nodes.splice(i, 0, node);
                done = inserted = true;
                break;
            }
        }
        if (!inserted) {
            done = parent.nodes.push(node);
            node.order = done - 1;
        }
    }
    if(inserted){
        for (let i = 0; i < parent.nodes.length; i++) {
            parent.nodes[i].order = i;
            parent.nodes[i]['edited'] = true;
        }
    }
    parent.nodes.sort((a, b) => a.order - b.order);

    return done ? true : false;
};
const findNodeByIdStack = (id, nodes) => {

    let stack = [{ nodes: nodes, parent: null }];

    while (stack.length > 0) {
        let { nodes, parent } = stack.pop();

        for (let index = 0; index < nodes.length; index++) {
            let node = nodes[index];

            if (node.id == id) {
                //console.log('findNodeById.node', id, node, nodes);
                return { node: node, index: index, parent: parent };
            }

            if (node.nodes && node.nodes.length > 0) {
                stack.push({ nodes: node.nodes, parent: node });
            }
        }
    }

    return null;
};

const isParent = (source, destination, nodes) => {
    //console.log('isParent', source, destination, nodes);
    var s = findNodeByIdStack(source, nodes);
    var d = findNodeByIdStack(destination, nodes);
    //console.log('isParent', s, d);
    do {
        //console.log('isParent', d.parent, s.id);
        if (d.node.parent == s.node.id) {
            return true;
        }
        d = findNodeByIdStack(d.node.parent, nodes);
    } while (d && d.node && d.node.parent);

    return false;
};

const on_drop = function (e, json) {
    //console.log(e)
    const deleteNode = (node) => {
        if (node.parent) {
            var deleted = node.parent.nodes.splice(node.index, 1)[0];
            if(deleted){
                for (let i = 0; i < node.parent.nodes.length; i++) {
                    node.parent.nodes[i].order = i;
                    node.parent.nodes[i]['edited'] = true;
                }
            }
            if (node.parent.nodes.length === 0) {
                delete node.parent.nodes;
            }
            return deleted;
        }
    };
    if (!!e.in && !!e.source && (e.in !== e.source)) {
        //var source = parseInt(e.source.getAttribute('data-id'));
        //var destination = parseInt(e.in.getAttribute('data-id'));
        var source = e.source.getAttribute('data-id');
        var destination = e.in.getAttribute('data-id');
        if (source && destination) {
            var isp = isParent(source, destination, json);
            //console.log('isp', isp);
            if (isParent(source, destination, json) === false) {
                //console.log(`source: ${source}, destination: ${destination}`);
                source = findNodeByIdStack(source, json);
                destination = findNodeByIdStack(destination, json);
                //console.log('source node', source, 'destination node', destination);
                if (source && destination) {
                    switch (true) {
                        case (e.edge && e.edge.indexOf('top') > -1): {
                            
                            var deleted = deleteNode(source);
                            deleted.order = destination.node.order;
                            deleted['edited'] = true;
                            console.log('attach before',deleted.order);
                            var done = insertInto(deleted, destination.node.parent, json, deleted.order);
                            if (done) {
                                return json;
                            }
                        } break;
                        case (e.edge && e.edge.indexOf('bottom') > -1): {
                            
                            var deleted = deleteNode(source);
                            deleted.order = destination.node.order + 1;
                            deleted['edited'] = true;
                            console.log('attach after', deleted.order);
                            var done = insertInto(deleted, destination.node.parent, json, deleted.order);
                            if (done) {
                                return json;
                            }
                        } break;
                        case (!e.edge): {
                            //console.log('make child', source.node, source.parent);
                            var deleted = deleteNode(source);
                            deleted['edited'] = true;
                            var done = insertInto(deleted, destination.node.id, json);
                            /*if (deleted) {
                                destination.node.nodes.push(deleted);
                                deleted.parent = destination.node.id;
                                //console.log('add node', deleted);
                            }*/
                            //console.log('updated', json);
                            return json;
                        } break;
                    }
                }
            }
        }
    }
};
let counter = 0;
const Tree = (p) => {
    const root = addRoot(p.json);
    const [json, setJson] = useState(root);
    const [selected, setSelected] = useState(null);
    const [surrogates, setSurrogates] = useState(null);
    const [orphan, setOrphan] = useState(null);
    const inputs = useRef({});
    const NewNodeRegExp = new RegExp('input\-[0-9]+\-[0-9]+');
    //console.log(root);
    useEffect(() => {

        window.DragDrop.check('listItem', {
            source: {
                isDraggable: function (e) {
                    if (e.hasAttribute('data-pickable')) {
                        var pickable = e.getAttribute('data-pickable');
                        //console.log('got',pickable, pickable === 'no', e);
                        if (pickable === 'yes') {
                            return true;
                        } else if (pickable === 'no') {
                            return null;
                        }
                    }
                },
                onDragIn: function (e) {
                    //console.log('Enter target:', e.colission);
                    e.in && (e.in.style.backgroundColor = '#f5f5f5');
                    e.in && (e.in.style.border = '1px solid #1E88E5');
                },
                onDragOut: function (e) {
                    //console.log('Exit target:', e);
                    e.out && (e.out.style.backgroundColor = null);
                    e.out && (e.out.style.border = null);
                },
                onDragOver: function (e) {
                    //console.log('Over target:', e.hover, e.edge);
                },
                onPick: function (e) {
                    e.source.style.opacity = '0.3';
                    //console.log('Picked:', e);
                },
                onDrop: function (e) {
                    //console.log('Dropped:', e);
                    onDrop(e);
                    e.source.style.opacity = '';
                    e.out && (e.out.style.backgroundColor = null);
                    e.out && (e.out.style.border = null);
                    e.in && (e.in.style.backgroundColor = null);
                    e.in && (e.in.style.border = null);
                }
            },
            target: {
                isTarget: function (e) { return (e.getAttribute('data-target') === 'yes'); },
            }
        });
        return () => {
            window.DragDrop.uncheck('listItem');
        }
    }, []);
    //console.log(json)
    const onDrop = function (e) {
        //console.log(e)
        var nodes = on_drop(e, json);
        //console.log('onDrop', nodes);
        if (nodes) {
            setJson([...nodes]);
        }

    };

    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('onClick', e.target);
    };
    const onEdit = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        let node = findNodeByIdStack(id, json);
        if (node) {
            node.node['edit'] = true;
            setJson([...json]);
        }
        //console.log('onEdit', node);
    };
    const onAdd = (e, id, tempId) => {
        e.preventDefault();
        e.stopPropagation();
        let node = {
            "id": tempId,
            "parent": id,
            "value": null,
            "order": null,
            "new": true,
            "edit": true
        };
        var done = insertInto(node, id, json);
        if (done) {
            setJson([...json]);
        }
        console.log('onAdd', id, tempId, done);
    };
    const onSave = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        let value = inputs.current[id]?.value?.trim();
        let node = findNodeByIdStack(id, json);
        let parent = findNodeByIdStack(node.node.parent, json) || json;
        //console.log('onSave', value, node, parent);
        if (value && node && parent) {
            node.node.value = value;
            delete inputs.current[id];
            delete node.node.edit;
            node.node['edited'] = true;
            setJson([...json]);
        }
    };
    const onNew = (e) => {
        e.preventDefault();
        e.stopPropagation();
        //console.log('onNew', e.target);
    };


    function render(json) {
        return (
            <>
                {(json && Array.isArray(json)) ? (<ul>{json.map((node, index) => {
                    //let exists = !NewNodeRegExp.test(node.id) && node.value?.trim() ? true : false;
                    let exists = node?.edit ? false : true;
                    let id = node.id || `input-${node.parent}-${index}`;
                    let newId = `input-${node.id}-${counter++}`;
                    let key = `${id}-index`;
                    return (
                        <li key={key} data-id={id} data-pickable="yes" data-target="yes" className={`tree-c-ul-li ${node?.nodes?.length > 0 && 'tree-c-connect'} ${(node.edited || node.new) && 'tree-edited'}`} >
                            {node?.nodes && <Checkbox split={true} id={key} data-pickable="no" />}
                            {exists ?
                                (<div id={id} parent={node?.parent} className={'tree-c-ul-li-div'} >
                                    <span className={(node.selected && 'tree-select') || (node.surrogate && 'tree-surrogate') || null}>
                                        {node?.value}
                                    </span>
                                    <figure data-pickable="no">
                                        {!isRoot(id) && <EditBox name={'editListItem'} onClick={(e) => onEdit(e, id)} />}
                                        {node?.nodes ?
                                            <AddToList name={'addToList'} onClick={(e) => onAdd(e, id, newId)} /> :
                                            <SubDirectoryRight name={'newList'} onClick={(e) => onAdd(e, id, newId)} />
                                        }
                                    </figure>
                                </div>) :
                                (<div id={id} className={'tree-c-ul-li-div'} data-pickable="no" >
                                    <input type="text" className='tree-edit' defaultValue={node?.value} ref={(e) => (inputs.current[id] = e)} />
                                    <figure data-pickable="no">
                                        <TickCircle onClick={(e) => onSave(e, id)} />
                                        <DeleteSvg />
                                    </figure>
                                </div>)}
                            {render(node?.nodes)}
                        </li>
                    );
                })}</ul>) : null}
            </>
        );
    }
    return (
        <div className="tree-c">{render(json)}</div>
    );
};

export default Tree;