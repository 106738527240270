import React, { useState } from 'react';
import { PlusSymbol, MinusSymbol } from '../icons/Icon';

export const Checkbox = (p) => {

    const onChange = (e) => {
        //console.log(p, e.target.checked);
    };
    if (p.split) {
        return (<>
            <input className='box-snap-input' type="checkbox" id={p.id} onChange={onChange} name={p?.name} defaultValue={p?.value} style={{ display: 'none' }} />
            <label data-pickable="no" className="box-snap" htmlFor={p.id}>
                <figure>
                    <PlusSymbol className="box-snap-checked" />
                    <MinusSymbol className="box-snap-unchecked" />
                </figure>
                {p?.label && <span>{p?.label}</span>}
            </label>
        </>);
    } else {
        return (
            <label data-pickable="no" className="box-snap" htmlFor={p.id}>
                <input type="checkbox" id={p.id} onChange={onChange} name={p?.name} defaultValue={p?.value} style={{ display: 'none' }} />
                <figure>
                    <PlusSymbol className="box-snap-checked" />
                    <MinusSymbol className="box-snap-unchecked" />
                </figure>
                {p?.label && <span>{p?.label}</span>}
            </label>
        );
    }

};