import { Fragment } from 'react';
import { Outlet } from "react-router-dom";
import { PlaceholderContextProvider, ModalPlaceholder } from './contexts/PlaceholderContextProvider';
import AppMain from "./components/app/AppMain";
import AppAside from "./components/app/AppAside";
import AppBeside from "./components/app/AppBeside";
import AppNav from "./components/app/AppNav";
import AppHeader from "./components/app/AppHeader";
import AppFooter from "./components/app/AppFooter";
import AppContent from "./components/app/AppContent";

function Root() {
    return (
        <PlaceholderContextProvider>
            <Fragment>
                <AppMain>
                    <ModalPlaceholder />
                    <AppAside>
                        <h1>Admin</h1>
                        <AppNav />
                    </AppAside>
                    <AppBeside>
                        <AppHeader />
                        <AppContent>
                            <Outlet />
                        </AppContent>
                        <AppFooter />
                    </AppBeside>
                </AppMain>
            </Fragment>
        </PlaceholderContextProvider>

    );
}

export default Root;