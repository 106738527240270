function fetcher(url) {
    return new Promise((resolve, reject) => {

    });
}
function $encodeURIComponent(s) {
    return encodeURIComponent(s).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
}
function $decodeURIComponent(s) {
    return decodeURIComponent(s).replace(/%21/g, '!').replace(/%27/g, '\'').replace(/%28/g, '(').replace(/%29/g, ')').replace(/%2A/g, '*');
}
function $getSelectedOptions(e, f, o, i, l, r) {
    if (e.type === 'select-one') {
        //console.log(e.options[0]);
        o = e.options[e.selectedIndex];
        //console.log(e.options[0]);
        //console.log(e.selectedIndex);
        //console.log(o);
        return (f === 'option') ? o : (o[f] || o.value || o.text);
    }
    if (e.type === 'select-multiple') {
        console.log(e);
        console.log(e.selectedOptions || e.options);
        if ((o = e.selectedOptions || e.options).length > 0) {
            for (i = 0, r = [], l = o.length; i < l; i++) {
                (o[i].selected) && r.push((f === 'option') ? o[i] : (o[i][f] || o[i].value || o[i].text));
            }
            return r;
        }
    }
}
export const apiSubmit = async (data) => {

    //await new Promise(resolve => setTimeout(resolve, 3000));
    console.log('apiSubmit', data);
    const response = await fetch(data.api, {
        method: data.method,
        headers: data.headers,
        body: JSON.stringify(data.data),
    });

    if (!response.ok) {
        throw new Error('Failed to submit data');
    }

    const content = await response.json();
    const status = response.status;
    const headers = response.headers;

    return { content, status };
};

export function makeUrl(s){
    return s.toLowerCase().replaceAll(makeUrl.gaps, '-');
}
makeUrl.gaps = /\s+/g;
export function trimStringGaps(s){
    return s.replaceAll(makeUrl.gaps, ' ');
}
trimStringGaps.gaps = /\s+/g;

function $serializeForm(f) {
    let arrayName = new RegExp('\\s*(.+?)\\s*\\[\\s*\\]\\s*');
    var query = [];
    var json = {};

    function select(select) {
        var options = null;
        if (select.type === 'select-one') {
            options = select.options[select.selectedIndex];
            addNameValue(select.name, options.value || options.text);
        }
        if (select.type === 'select-multiple') {
            if ((options = select.selectedOptions || select.options).length > 0) {
                for (var i = 0; i < options.length; i++) {
                    (options[i].selected) && addNameValue(select.name, options[i].value || options[i].text);
                }
            }
        }
    }
    function check(e) {
        if (e.checked) {
            addNameValue(e.name, e.value);
        }
    }
    function addNameValue(name, value) {
        var found = arrayName.exec(name);
        if (found) {
            name = found[1];
            if (!(name in json)) {
                json[name] = [];
            }
        }
        query.push($encodeURIComponent(name) + '=' + $encodeURIComponent(value || ' '));
        if (Array.isArray(json[name])) {
            json[name].push(value);
        } else {
            json[name] = value || '';
        }
    }
    if (!f || f.nodeName !== "FORM") {
        return;
    }
    for (var i = 0; i < f.elements.length; i++) {
        if (f.elements[i].name === "" || f.elements[i].disabled) {
            continue;
        }
        switch (f.elements[i].nodeName) {
            case 'INPUT':
                switch (f.elements[i].type) {
                    case 'text':
                    case 'email':
                    case 'number':
                    case 'tel':
                    case 'date':
                    case 'datetime':
                    case 'datetime-local':
                    case 'month':
                    case 'week':
                    case 'time':
                    case 'url':
                    case 'hidden':
                    case 'password':
                    case 'button':
                    case 'reset':
                    case 'submit':
                    case 'range':
                        addNameValue(f.elements[i].name, f.elements[i].value);
                        break;
                    case 'checkbox':
                    case 'radio':
                        check(f.elements[i]);
                        break;
                    case 'file':
                        break;
                }
                break;
            case 'TEXTAREA':
                addNameValue(f.elements[i].name, f.elements[i].value);
                break;
            case 'SELECT':
                select(f.elements[i]);
                break;
            case 'BUTTON':
                switch (f.elements[i].type) {
                    case 'reset':
                    case 'submit':
                    case 'button':
                        addNameValue(f.elements[i].name, f.elements[i].value);
                        break;
                }
                break;
        }
    }
    return [query.join("&"), json];
}

export { $serializeForm };