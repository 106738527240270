import { combineReducers, bindActionCreators } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import ServicesSlice from './slices/ServicesSlice';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga/RootSaga';

const initialState = {
    moduleMenu: null,
};
export const rootReducer = combineReducers({
    services: ServicesSlice.reducer
});
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;