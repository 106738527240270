import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { $serializeForm } from "../../main";
import { CREATE_SERVICE } from "../../api/Api";
import { ServicesActions } from "../../redux/slices/ServicesSlice";
import ServiceForm from "./ServiceForm";

const NewService = () => {

    const [value, setValue] = useState('');
    const dispatch = useDispatch();
    const createService = useSelector((state) => state.services.setService);

    function changed(e) {
        e.preventDefault();
    }
    function submit(service) {
        var [encoded, json] = $serializeForm(service);
        console.log('service.json', json);
        //dispatch(ServicesActions.setService({ ...CREATE_SERVICE, data: json }));
    }

    return (
        <div className="page">
            <ServiceForm onSubmit={submit} onChange={changed} label={createService} />
        </div>
    );
};

export default NewService;