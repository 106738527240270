import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Root from "../Root";
import NewProduct from "../components/product/NewProduct";
import NewService from "../components/service/NewService";
import SearchService from "../components/service/SearchService";
import TreePage from "../components/TreePage";

function ProductRoutes(){
    return(
        <Routes>
            <Route index element={<div>default page</div>} />
            <Route path="new" element={<NewProduct />} />
            <Route path="dashboard" element={<div>dashboard</div>} />
            <Route path="search" element={<div>search</div>} />
        </Routes>
    );
}
function ServiceRoutes(){
  return(
      <Routes>
          <Route index element={<div>service page</div>} />
          <Route path="new-service" element={<NewService />} />
          <Route path="dashboard" element={<div>dashboard</div>} />
          <Route path="search-service" element={<SearchService />} />
      </Routes>
  );
}
function AppRoutes() {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<Root />}>
            <Route path="/" element={<TreePage />} />
            <Route path="/service/*" element={<ServiceRoutes />} />
            <Route path="/product/*" element={<ProductRoutes />} />
            <Route path="/staff" element={<div>staff</div>} />
            <Route path="*" element={<p>error</p>} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
  export default AppRoutes;