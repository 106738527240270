import React, { useState } from 'react';

const ConfirmButton = ({ onConfirm, label, className }) => {

    const [confirm, setConfirm] = useState(false);

    const action = (e) => {
        e.preventDefault();
        setConfirm(true);
    };

    const yes = (e) => {
        e.preventDefault();
        onConfirm(e);
        setConfirm(false);
    };

    const no = (e) => {
        e.preventDefault();
        setConfirm(false);
    };

    return (
        <div className={className}>
            {confirm ? (
                <>
                    <button onClick={yes} >Yes</button>
                    <button onClick={no}  >No</button>
                </>
            ) : (
                <button onClick={action} >{label || 'Submit'}</button>
            )}
        </div>
    );
};

export default ConfirmButton;
