export const treeJson = {
  "nodes": [
    {
      "id": 1,
      "parent": null,
      "value": "Electronics",
      "order": 0,
      "nodes": [
        {
          "id": 2,
          "parent": 1,
          "value": "Mobile Phones",
          "order": 0,
          "nodes": [
            {
              "id": 40,
              "parent": 2,
              "value": "Smartphones",
              "order": 0
            },
            {
              "id": 41,
              "parent": 2,
              "value": "Feature Phones",
              "order": 1
            },
            {
              "id": 42,
              "parent": 2,
              "value": "Foldable Phones",
              "order": 2
            }
          ]
        },
        {
          "id": 3,
          "parent": 1,
          "value": "Laptops",
          "order": 1,
          "nodes": [
            {
              "id": 43,
              "parent": 3,
              "value": "Gaming Laptops",
              "order": 0
            },
            {
              "id": 44,
              "parent": 3,
              "value": "Business Laptops",
              "order": 1
            },
            {
              "id": 45,
              "parent": 3,
              "value": "Ultrabooks",
              "order": 2
            }
          ]
        },
        {
          "id": 4,
          "parent": 1,
          "value": "Accessories",
          "order": 2,
          "nodes": [
            {
              "id": 5,
              "parent": 4,
              "value": "Phone Cases",
              "order": 0
            },
            {
              "id": 6,
              "parent": 4,
              "value": "Chargers",
              "order": 1
            },
            {
              "id": 7,
              "parent": 4,
              "value": "Headphones",
              "order": 2,
              "nodes": [
                {
                  "id": 8,
                  "parent": 7,
                  "value": "In-ear",
                  "order": 0
                },
                {
                  "id": 9,
                  "parent": 7,
                  "value": "Over-ear",
                  "order": 1
                },
                {
                  "id": 10,
                  "parent": 7,
                  "value": "Wireless",
                  "order": 2,
                  "nodes": [
                    {
                      "id": 11,
                      "parent": 10,
                      "value": "Bluetooth",
                      "order": 0
                    },
                    {
                      "id": 12,
                      "parent": 10,
                      "value": "Noise Cancelling",
                      "order": 1
                    },
                    {
                      "id": 13,
                      "parent": 10,
                      "value": "Gaming Headsets",
                      "order": 2
                    }
                  ]
                }
              ]
            },
            {
              "id": 46,
              "parent": 4,
              "value": "Screen Protectors",
              "order": 3
            },
            {
              "id": 47,
              "parent": 4,
              "value": "Cables",
              "order": 4
            },
            {
              "id": 48,
              "parent": 4,
              "value": "Power Banks",
              "order": 5
            }
          ]
        },
        {
          "id": 67,
          "parent": 1,
          "value": "Smartwatches",
          "order": 3
        },
        {
          "id": 68,
          "parent": 1,
          "value": "Cameras",
          "order": 4
        },
        {
          "id": 69,
          "parent": 1,
          "value": "TVs",
          "order": 5
        }
      ]
    },
    {
      "id": 14,
      "parent": null,
      "value": "Fashion",
      "order": 1,
      "nodes": [
        {
          "id": 15,
          "parent": 14,
          "value": "Men's Clothing",
          "order": 0,
          "nodes": [
            {
              "id": 49,
              "parent": 15,
              "value": "T-Shirts",
              "order": 0
            },
            {
              "id": 50,
              "parent": 15,
              "value": "Jeans",
              "order": 1
            },
            {
              "id": 51,
              "parent": 15,
              "value": "Jackets",
              "order": 2
            }
          ]
        },
        {
          "id": 16,
          "parent": 14,
          "value": "Women's Clothing",
          "order": 1,
          "nodes": [
            {
              "id": 52,
              "parent": 16,
              "value": "Dresses",
              "order": 0
            },
            {
              "id": 53,
              "parent": 16,
              "value": "Skirts",
              "order": 1
            },
            {
              "id": 54,
              "parent": 16,
              "value": "Blouses",
              "order": 2
            }
          ]
        },
        {
          "id": 17,
          "parent": 14,
          "value": "Accessories",
          "order": 2,
          "nodes": [
            {
              "id": 18,
              "parent": 17,
              "value": "Watches",
              "order": 0
            },
            {
              "id": 19,
              "parent": 17,
              "value": "Jewelry",
              "order": 1
            },
            {
              "id": 20,
              "parent": 17,
              "value": "Handbags",
              "order": 2,
              "nodes": [
                {
                  "id": 21,
                  "parent": 20,
                  "value": "Tote Bags",
                  "order": 0
                },
                {
                  "id": 22,
                  "parent": 20,
                  "value": "Shoulder Bags",
                  "order": 1
                },
                {
                  "id": 23,
                  "parent": 20,
                  "value": "Wallets",
                  "order": 2,
                  "nodes": [
                    {
                      "id": 24,
                      "parent": 23,
                      "value": "Leather Wallets",
                      "order": 0
                    },
                    {
                      "id": 25,
                      "parent": 23,
                      "value": "Card Holders",
                      "order": 1
                    },
                    {
                      "id": 26,
                      "parent": 23,
                      "value": "Clutch Wallets",
                      "order": 2
                    }
                  ]
                }
              ]
            },
            {
              "id": 55,
              "parent": 17,
              "value": "Belts",
              "order": 3
            },
            {
              "id": 56,
              "parent": 17,
              "value": "Scarves",
              "order": 4
            },
            {
              "id": 57,
              "parent": 17,
              "value": "Sunglasses",
              "order": 5
            }
          ]
        },
        {
          "id": 70,
          "parent": 14,
          "value": "Shoes",
          "order": 3
        },
        {
          "id": 71,
          "parent": 14,
          "value": "Hats",
          "order": 4
        },
        {
          "id": 72,
          "parent": 14,
          "value": "Swimwear",
          "order": 5
        }
      ]
    },
    {
      "id": 27,
      "parent": null,
      "value": "Home & Kitchen",
      "order": 2,
      "nodes": [
        {
          "id": 28,
          "parent": 27,
          "value": "Furniture",
          "order": 0,
          "nodes": [
            {
              "id": 58,
              "parent": 28,
              "value": "Sofas",
              "order": 0
            },
            {
              "id": 59,
              "parent": 28,
              "value": "Chairs",
              "order": 1
            },
            {
              "id": 60,
              "parent": 28,
              "value": "Tables",
              "order": 2
            }
          ]
        },
        {
          "id": 29,
          "parent": 27,
          "value": "Decor",
          "order": 1,
          "nodes": [
            {
              "id": 61,
              "parent": 29,
              "value": "Wall Art",
              "order": 0
            },
            {
              "id": 62,
              "parent": 29,
              "value": "Lamps",
              "order": 1
            },
            {
              "id": 63,
              "parent": 29,
              "value": "Rugs",
              "order": 2
            }
          ]
        },
        {
          "id": 30,
          "parent": 27,
          "value": "Kitchen Appliances",
          "order": 2,
          "nodes": [
            {
              "id": 64,
              "parent": 30,
              "value": "Mixers",
              "order": 0
            },
            {
              "id": 65,
              "parent": 30,
              "value": "Ovens",
              "order": 1
            },
            {
              "id": 66,
              "parent": 30,
              "value": "Refrigerators",
              "order": 2
            }
          ]
        },
        {
          "id": 73,
          "parent": 27,
          "value": "Bedding",
          "order": 3
        },
        {
          "id": 74,
          "parent": 27,
          "value": "Cookware",
          "order": 4
        },
        {
          "id": 75,
          "parent": 27,
          "value": "Tableware",
          "order": 5
        }
      ]
    }
  ]
};
