import { useEffect, useRef, useState, useContext } from "react";
import Select from 'react-select';

const SelectHtml = (p) => {

    const select = useRef(null);
    const [selected, setSelected] = useState(p?.selected.map(select => select.value || ''));

    const onChange = (e) => {
        const selectedValues = Array.from(e.target.selectedOptions, option => option.value);
        //console.log('SelectHtml.onChange',selectedValues)
        setSelected(selectedValues);
    };
    useEffect(() => {
        //console.log('SelectHtml.selected',p.selected)
        setSelected(p?.selected.map(select => select.value || ''));
    }, [p.selected]);

    return (
        <select name={p.name || ''} ref={select} style={p.style || {}} id={'so'} multiple={p.multiple} value={selected} onChange={onChange} >
            {p.options && p.options.map((option) => (<option key={option.value} value={option.value} >{option.label}</option>))}
        </select>
    );
};
function SelectOptionsSection(p) {

    const [selected, setSelected] = useState(p.selected || []);

    useEffect(() => {
        //console.log('SelectOptionsSection.selected',selected)
        setSelected(p.selected)
    }, [p.selected]);

    return (
        <div>
            <Select
                isMulti={p.multiple}
                options={p.options || []}
                defaultValue={selected}
                onChange={setSelected}
            />
            <SelectHtml name={p.name || ''} options={p.options || []} selected={selected} multiple={p.multiple} style={{display:'none'}} />
        </div>

    );
}

export { SelectOptionsSection, SelectHtml };