import Pagination from "../../widgets/Pagination";

function AppHeader(p) {
    return (
        <header className="header">
            <div><Pagination /></div>
            {p.children}
        </header>
    );
}

export default AppHeader;