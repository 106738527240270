export const DOMAIN = 'http://localhost/global_work_travel/public/api';
export const AUTHORIZED = `${DOMAIN}/authorized`;
export const HOST = "http://localhost/global_work_travel/public/api";

export const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
};
export const CREATE_SERVICE = {
    api: HOST + '/services',
    method: 'POST',
    headers: DEFAULT_HEADERS
};
export const GET_ALL_SERVICE = {
    api: HOST + '/services/all',
    method: 'GET',
    headers: DEFAULT_HEADERS
};
export const UPDATE_SERVICE = (id) => ({
    api: `${HOST}/services/${id}`,
    method: 'PUT',
    headers: DEFAULT_HEADERS
});