import React, { useState } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

function MyQuillEditor(p) {

    const [editorHtml, setEditorHtml] = useState(p.value || '');

    const handleChange = (html) => {
        setEditorHtml(html);
    };
    const onChange = (e) => {

    };
    return (
        <>
            <ReactQuill
                value={editorHtml}
                onChange={handleChange}
                modules={MyQuillEditor.modules}
                formats={MyQuillEditor.formats}
                style={{ height: '400px', boxSizing: 'border-box' }}
            />
            {/**  <div dangerouslySetInnerHTML={{ __html: editorHtml }} />*/}
            <textarea name={p?.name || 'quill-textarea'}  onChange={onChange} value={editorHtml || ''} style={{ display: 'none' }} ></textarea>
        </>
    );
}
MyQuillEditor.modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        ['bold', 'italic', 'underline'],
        [{ 'align': [] }],
        ['link', 'image'],
        [{ 'color': [] }, { 'background': [] }],
        ['clean'],
        ['blockquote', 'code-block'],
        ['direction'],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }]
    ],
};
//'bullet','clean',
MyQuillEditor.formats = [
    'header', 'font', 'list',  'script', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'color', 'background',  'blockquote', 'code-block', 'direction', 'indent', 'size'
];
export default MyQuillEditor;
