import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: null,
    setService: 'create service'
};

const ServicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        setService: (state, action) => {
            console.log('setService', action.payload);
            state.setService = 'loading';
        },
        createService: (state, action) => {
            console.log('createService', action.payload);
            state.setService = 'creating...';
        },
        setServiceSuccess: (state, action) => {
            console.log('setServiceSuccess', action.payload);
            state.setService = 'done';
        },
        setServiceFailure: (state, action) => {
            console.log('setServiceFailure', action.payload);
            state.setService = 'try again';
        },
        putService: (state, action) => {
            console.log('put', action.payload);
        },
        updateService: (state, action) => {
            console.log('update', action.payload);
        },
        putServiceSuccess: (state, action) => {
            console.log('putSuccess', action.payload);
        },
        putServiceFailure: (state, action) => {
            console.log('putFailure', action.payload);
        },
        deleteService: (state, action) => {
            console.log('delete', action.payload);
        },
        removeService: (state, action) => {
            console.log('remove', action.payload);
        },
        deleteServiceSuccess: (state, action) => {
            console.log('putSuccess', action.payload);
        },
        deleteServiceFailure: (state, action) => {
            console.log('putFailure', action.payload);
        },
        getAllService: (state, action) => {

        },
        fetchAllService: (state, action) => {

        },
        getAllServiceSuccess: (state, action) => {
            state.data = action.payload.content;
        },
        getAllServiceFailure: (state, action) => {

        },
        editUnit: (state, action) => {
            let [module] = state.data.filter(module => module.id === action.payload);
            state.selected = module;
        }
    },
});

export const ServicesActions = ServicesSlice.actions;
export default ServicesSlice;