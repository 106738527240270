import { takeEvery, call, put, all } from 'redux-saga/effects';
import { ServicesActions } from '../redux/slices/ServicesSlice';
import { apiSubmit } from '../main';

function* handleSetService(action) {
  try {
    console.log('handleSetService', action.payload);
    const response = yield call(apiSubmit, action.payload);
    console.log('handleSetService.response', response);
    //ServicesActions.createService
    yield put(ServicesActions.setServiceSuccess(response));
  } catch (e) {
    console.log('handleSet.catch', e.message);
    yield put(ServicesActions.setServiceFailure(e.message));
  }finally{
    console.log('handleSet.finally', 'done');
  }
}

export function* watchSetService() {
  yield takeEvery(ServicesActions.setService, handleSetService);
  //yield takeLatest('INITIALIZE', initializeSagaWorker);
}

function* handleGetAllService(action) {
  try {
    const response = yield call(apiSubmit, action.payload);
    yield put(ServicesActions.getAllServiceSuccess(response));
  } catch (e) {
    yield put(ServicesActions.getAllServiceFailure(e.message));
  }finally{

  }
}
export function* watchGetAllService() {
  yield takeEvery(ServicesActions.getAllService, handleGetAllService);
}

function* handlePutService(action) {
  try {
    console.log('handlePutService', action);
    const response = yield call(apiSubmit, action.payload);
    console.log('handlePutService.response', response);
    yield put(ServicesActions.putServiceSuccess(response));
  } catch (e) {
    console.log('handlePutService.catch', e.message);
    yield put(ServicesActions.putServiceFailure(e.message));
  }finally{

  }
}
export function* watchPutService() {
  yield takeEvery(ServicesActions.putService, handlePutService);
}